<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("editdistrict") }}
        </h3>
      </div>
      <div class="card-body">
        <div class="row text-sm-start d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label required"
              >District Code</label
            >
            <input v-model="form.code" class="form-control mb-4" />
          </div>
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label required"
              >District Name</label
            >
            <input v-model="form.name" class="form-control mb-4" />
          </div>
          <!-- <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">valid from</label>
            <input v-model="valid" class="form-control mb-4" type="date" />
          </div> -->
        </div>
      </div>
      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar">
          <button
            type="submit"
            class="btn btn-sm btn-light btn-primary me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submit()"
          >
            {{ $t("save") }}
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </footer>
    </form>
  </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import promotionAPI from "@/api/promotion/";
export default {
  data: () => ({
    form: {
      code: "",
      name: "",
    },
    // valid: new Date(),
  }),
  created() {
    this.getOne();
  },
  methods: {
    async getOne() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await promotionAPI.district.getOne(
          this.$route.query.id
        );
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.form = {
          ...getOneResponse.data,
        };
        this.loading = false;
        console.log("getOneResponse.data", getOneResponse.data);
      }
    },
    async submit() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.validateForm();
          await this.checkFormIsEmpty();
          if (this.isValid) {
            let createResponse = [];

            this.loading = true;

            this.form = {
              ...this.form,
              companyId: parseInt(localStorage.getItem("companyId")),
            };

            createResponse = await promotionAPI.district.update(
              this.$route.query.id,
              this.form
            );
            if (createResponse.response_status === "SUCCESS") {
              Swal.fire({
                icon: "success",
                title: "แก้ไขสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push("/promotion/district/index");
              });
              this.loading = false;
            } else {
              Swal.fire({
                icon: "error",
                title: "แก้ไขไม่สำเร็จ !",
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
            }
          }
        }
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    checkFormIsEmpty() {
      !this.form.code
        ? (this.isValid = false)
        : !this.form.name
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            if (!form.checkValidity()) {
              event.preventDefault();
              event.stopPropagation();
            }

            form.classList.add("was-validated");
          },
          false
        );
      });
    },
  },
};
</script>